import { createContext, useEffect, useState, useMemo } from 'react';
import { getStorageValue } from '@portal-internet/core';
export const ModalContext = createContext();

const FULL_SCREEN = 'full-screen';

const defaultOptions = {
    mode: FULL_SCREEN
};

const ModalProvider = (props) => {
    const { children } = props;

    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [optionsModal, setOptionsModal] = useState(defaultOptions);
    const [mutePlayer, setMutePlayer] = useState(false);

    const setModal = (content, options = defaultOptions) => {
        options = { ...defaultOptions, ...options };
        setOpenModal(true);
        setOptionsModal(options);
        setModalContent(content);

        if (typeof document !== 'undefined' && options.mode === FULL_SCREEN) {
            if (options.keepScrollbar === true) {
                document.body.classList.add('is-modal-fullscreen-keep-scrollbar');
            } else {
                document.body.classList.add('is-modal-fullscreen');
            }
        }
    };

    const removeModal = () => {
        if (typeof document !== 'undefined' && optionsModal.mode === FULL_SCREEN) {
            document.body.classList.remove('is-modal-fullscreen-keep-scrollbar');
            document.body.classList.remove('is-modal-fullscreen');
        }

        setOpenModal(false);
        setModalContent(null);
    };

    const centerDivOnModal = (idDiv, smooth = true) => {
        const containerScroll = document.getElementById('container-modal');
        const centeredDiv = document.getElementById(idDiv);
        if (centeredDiv) {
            const containerScrollHeight = containerScroll.clientHeight;
            const centeredDivHeight = centeredDiv.clientHeight;
            if (smooth) {
                containerScroll.scrollTo({
                    top: centeredDiv.offsetTop + centeredDivHeight / 2 - containerScrollHeight / 2,
                    behavior: 'smooth'
                });
            } else {
                containerScroll.scrollTop = centeredDiv.offsetTop + centeredDivHeight / 2 - containerScrollHeight / 2;
            }
        }
    };

    const topDivOnModal = (idDiv, smooth = true) => {
        const containerScroll = document.getElementById('container-modal');
        const topDiv = document.getElementById(idDiv);
        const marginDiv = 40;
        if (topDiv) {
            if (smooth) {
                containerScroll.scrollTo({
                    top: topDiv.offsetTop - marginDiv,
                    behavior: 'smooth'
                });
            } else {
                containerScroll.scrollTop = topDiv.offsetTop - marginDiv;
            }
        }
    };

    const setPlayerMutedByCCMAStorage = async () => {
        const muted = (await getStorageValue('bmplayer.3cat.muted')) === 'true';
        setMutePlayer(muted);
    };

    useEffect(() => {
        setPlayerMutedByCCMAStorage();
    }, []);

    const contextValue = useMemo(() => ({
        openModal,
        modalContent,
        optionsModal,
        mutePlayer,
        setModal,
        removeModal,
        centerDivOnModal,
        topDivOnModal,
        setMutePlayer
    }), [modalContent, optionsModal, mutePlayer]);


    return (
        <ModalContext.Provider
            value={contextValue} >
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
