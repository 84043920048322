import React, { useEffect, useRef, useState } from 'react';
import { useModal } from 'hooks/3catinfo/useModal';
import Icona from 'components/3catinfo/ui/Icona';
import clsx from 'clsx';
import styles from './modal.module.scss';
import { useRouter } from 'next/router';

const Modal = (props) => {
    const { openModal, modalContent, optionsModal, removeModal } = useModal();
    const [topContentScroll, setTopContentScroll] = useState(0);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const contentRef = useRef(null);
    const modalRef = useRef(null);
    const router = useRouter();
    const styleModal = clsx({
        [styles.modal]: true,
        //[styles.noControls]: optionsModal?.showControls === false,
        [styles.visible]: isVisibleModal,
        [styles[optionsModal?.mode]]: !!optionsModal?.mode,
        [styles[optionsModal?.style]]: !!optionsModal?.style,
        [optionsModal.className]: !!optionsModal?.className
    });

    const handleClickOutside = (e) => {
        if (modalRef.current.contains(e.target) && contentRef.current && !contentRef.current.contains(e.target)) {
            removeModal();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            onClickClose();
        }
    };

    useEffect(() => {
        if (modalContent) {
            if (optionsModal.mode !== 'full-screen') setTopContentScroll(window.scrollY);
            if (optionsModal.closeOnClickOutside) {
                modalRef.current.addEventListener('mousedown', handleClickOutside);
            } else {
                modalRef.current.removeEventListener('mousedown', handleClickOutside);
            }
        }

        return () => {
            if (modalRef.current) modalRef.current.removeEventListener('mousedown', handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalContent]);

    useEffect(() => {
        if (openModal) {
            //Assegurem que no es canvia l'estat fins després del render
            setTimeout(() => {
                setIsVisibleModal(true);
            }, 0);
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [openModal]);

    const onClickClose = () => {
        if (optionsModal?.onClickClose) {
            optionsModal.onClickClose();
        } else {
            setIsVisibleModal(false);
            setTimeout(() => {
                removeModal();
            }, 300);
        }
        if (optionsModal?.originFocusRef?.current) {
            setTimeout(() => optionsModal?.originFocusRef?.current?.focus(), 0);
        }
    };

    // Detect URL changes using Next.js useRouter
    useEffect(() => {
        const handleRouteChange = () => {
            onClickClose();
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router]);

    return openModal ? (
        <div
            className={styleModal}
            id='container-modal'
            role='dialog'
            aria-modal='true'
            aria-label={optionsModal?.title}
            style={{ top: topContentScroll }}
            ref={modalRef}
        >
            <div className={styles.outside} onClick={onClickClose}></div>
            {/* <Controls optionsModal={optionsModal} onClickClose={onClickClose} /> */}
            <div className={styles.inner} id='content-modal' ref={contentRef}>
                {React.isValidElement(modalContent)
                    ? React.cloneElement(modalContent, { onClickClose, isVisibleModal })
                    : modalContent}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Modal;
