import { forwardRef } from 'react';
import Icona from 'components/3catinfo/ui/Icona/Icona';
import Enllac from 'components/3catinfo/Enllac';
import clsx from 'clsx';
import __isEmpty from 'lodash/isEmpty';
import styles from './boto.module.scss';

const Boto = forwardRef((props, ref) => {
    const {
        tag,
        text,
        tema,
        fons,
        estil,
        novaFinestra,
        rel,
        mida,
        link,
        linkTipus,
        linkId,
        linkSlug,
        slug,
        slug2,
        slugPrograma,
        tipologia,
        tipologiaRelacionada,
        canals,
        type,
        format,
        icona,
        altIcona,
        iconaInline,
        isIconaDreta,
        isIconaBlanc,
        id,
        onClick,
        name,
        className,
        dataTestId,
        data_testid,
        cleanUrlParams,
        tagLabel,
        ariaPressed
    } = props;

    const dataTestAttribute = dataTestId || data_testid;

    let { route } = props;

    const TagLabel = tagLabel || 'span';

    const estilArray = estil ? estil.split(' ') : [];

    const stylesBoto = clsx(
        estil !== 'noEstil' && styles.boto,
        estilArray.map((current) => styles[current]).filter(Boolean),
        tema && styles[tema],
        mida && styles[mida],
        fons && styles[fons],
        icona && styles[icona],
        !__isEmpty(className) && className
    );

    const _buttonContent = (text, icona, isIconaDreta) => {
        if (icona) {
            const iconPositionClass = isIconaDreta ? styles.dreta : '';
            const icon = (
                <Icona
                    className={`${styles.icona} ${iconPositionClass}`}
                    icona={icona}
                    inline={iconaInline}
                    alt={altIcona}
                    mida={mida}
                    ariaHidden={__isEmpty(altIcona)}
                    isBlanc={isIconaBlanc}
                />
            );

            const divIcon = (
                <div className={`${styles.iconWrapper}`}>
                    <div className={`${styles.iconaPrincipal}`}>{icon}</div>
                </div>
            );
            return (
                <>
                    {!isIconaDreta && divIcon}
                    {text && (
                        <TagLabel data-testid={dataTestAttribute} className={styles.label}>
                            {text}
                        </TagLabel>
                    )}
                    {isIconaDreta && divIcon}
                </>
            );
        } else {
            return (
                <TagLabel className={styles.label}>{text}</TagLabel>
            );
        }
    };

    const defaultRoute = {
        href: link,
        slug: linkSlug || slug,
        slugPrograma: slugPrograma,
        slugRelacio: slug2,
        id: linkId,
        tipologia: tipologia,
        tipologiaRelacionada: tipologiaRelacionada,
        canals: canals,
        type: type || linkTipus,
        format: format
    };

    const _buttonContentRouted = (text, icona, isIconaDreta) => {
        const iconPositionClass = isIconaDreta ? styles.dreta : '';
        const icon = (
            <Icona
                className={`${styles.icona} ${iconPositionClass}`}
                icona={icona}
                inline={iconaInline}
                alt={altIcona}
                mida={mida}
                ariaHidden={__isEmpty(altIcona)}
                isBlanc={isIconaBlanc}
            />
        );

        const handleKeyDown = (e, onClick) => {
            if ((e.key === 'Enter' || e.key === ' ') && typeof onClick === 'function') {
            onClick(e);
            }
        };

        const divIcon = (
            <div
                className={`${styles.iconWrapper}`}
                onClick={onClick}
                onKeyDown={(e) => handleKeyDown(e, onClick)}
                tabIndex={0}
                role="button"
                aria-label="Icon"
            >
                <div className={`${styles.iconaPrincipal}`}>{icon}</div>
            </div>
        );

        route = route || defaultRoute;

        return (
            <>
                {!isIconaDreta && divIcon}
                {text && (
                    <Enllac
                        route={route}
                        className={stylesBoto}
                        dataTestId={dataTestAttribute}
                        novaFinestra={novaFinestra}
                        rel={rel}
                        cleanUrlParams={cleanUrlParams}
                    >
                        <TagLabel data-testid={dataTestAttribute} className={styles.label}>
                            {text}
                        </TagLabel>
                    </Enllac>
                )}
                {isIconaDreta && divIcon}
            </>
        );
    };

    const _buttonLink = () => {
        route = route || defaultRoute;

        return (
            <Enllac
                route={route}
                className={stylesBoto}
                dataTestId={dataTestAttribute}
                novaFinestra={novaFinestra}
                rel={rel}
                cleanUrlParams={cleanUrlParams}
            >
                {_buttonContent(text, icona, isIconaDreta)}
            </Enllac>
        );
    };

    const _button = () => {
        return (
            <button
                ref={ref}
                className={stylesBoto}
                type={type}
                id={id}
                name={name}
                onClick={onClick}
                data-testid={dataTestAttribute}
                aria-pressed={ariaPressed}
            >
                {_buttonContent(text, icona, isIconaDreta)}
            </button>
        );
    };

    const _buttonRouted = () => {
        return (
            <button ref={ref} className={stylesBoto} type={type} id={id} name={name} data-testid={dataTestAttribute}>
                {_buttonContentRouted(text, icona, isIconaDreta)}
            </button>
        );
    };

    let content;

    if (tag === 'button') {
        content = _button();
    } else if (tag === 'divContentRouted') {
        content = _buttonRouted();
    } else {
        content = _buttonLink();
    }

    return content;
});

Boto.defaultProps = {
    tag: 'a',
    estil: 'ple',
    altIcona: '',
    mida: 16,
    isIconaDreta: true,
    onClick: undefined,
    iconaInline: false,
    isIconaBlanc: false,
    tagLabel: 'span'
};

export default Boto;

Boto.displayName = 'Boto';
