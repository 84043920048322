import { useEffect, useState, useRef, useCallback, memo } from 'react';
import clsx from 'clsx';

import { computSimpleHashFromData } from '../utils/utils';

import styles from './redlnotrenderableinfo.module.scss';

const FADE_OUT_DURATION = 300; // Match this to the CSS transition duration

function transformData(data) {
    return Object.entries(data).reduce((acc, [source, items]) => {
        items.forEach(({ componentName, blocIdx }) => {
            acc[blocIdx] ??= {};
            acc[blocIdx][source] ??= {};
            acc[blocIdx][source][componentName] = (acc[blocIdx][source][componentName] || 0) + 1;
        });
        return acc;
    }, {});
}

function generateGroupedMessages(transformedData) {
    return Object.entries(transformedData).map(([blocIdx, sources]) => {
        const totalItemsMissing = Object.values(sources)
            .flatMap((components) => Object.values(components))
            .reduce((sum, count) => sum + count, 0);

        return `Al bloc número ${blocIdx} li ${totalItemsMissing > 1 ? "manquen" : "manca"} ${totalItemsMissing} ${
            totalItemsMissing > 1 ? "ítems" : "ítem"
        } per mostrar-se correctament.`;
    });
}

const RedlNotRenderableInfo = memo(({ pageNotRenderableInfo }) => {
    const [messages, setMessages] = useState([]);
    const [isVisible, setIsVisible] = useState(true);
    const [isFading, setIsFading] = useState(false);
    const prevHashRef = useRef(null);

    useEffect(() => {
        if (!pageNotRenderableInfo) return;

        const currentHash = computSimpleHashFromData(pageNotRenderableInfo);
        if (currentHash === prevHashRef.current) return;

        const transformedData = transformData(pageNotRenderableInfo);
        const updatedMessages = generateGroupedMessages(transformedData);

        setMessages(updatedMessages);
        setIsVisible(updatedMessages.length > 0);

        prevHashRef.current = currentHash;
    }, [pageNotRenderableInfo]);

    const handleClose = useCallback(() => {
        setIsFading(true);
        setTimeout(() => {
            setIsVisible(false);
        }, FADE_OUT_DURATION);
    }, []);

    const containerClass = clsx(styles.redlNotRenderableInfo, {
        [styles.fading]: isFading,
        [styles.notVisible]: !messages || !isVisible
    });

    return (
        <div className={containerClass} data-testid='container-redlNotRenderableInfo'>
            <button className={styles.closeButton} onClick={handleClose} data-testid='btn-close-redlNotRenderableInfo'>
                <img src="/_resources/icones/negre/tanca-petit.svg" alt="Tancar"/>
            </button>
            {messages.map((message, index) => (
                <p key={index} className={styles.redlNotRenderableInfo__text}>
                    {message}
                </p>
            ))}
        </div>
    );
});

RedlNotRenderableInfo.displayName = 'RedlNotRenderableInfo';
export default RedlNotRenderableInfo;
