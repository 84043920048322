import { getStorageValue, setStorageValue } from '@portal-internet/core';
import { getData } from '@portal-internet/bff';
import { useUser } from 'hooks/useUser';
import __get from 'lodash/get';
import __isEmpty from 'lodash/isEmpty';
import { createContext, useEffect, useState, useMemo } from 'react';

const expiresInMinutes = 5;
const minutesPerDay = 1440;
const expires = expiresInMinutes / minutesPerDay;

const urlBase = 'https://api.3cat.cat';
export const ContentUserContext = createContext();

const ContentUserProvider = (props) => {
    const { children } = props;
    const { isAuthenticated, accessToken, uuid } = useUser();

    const [itemsLaMevaLlista, setItemsLaMevaLlista] = useState([{}]);
    const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const RESPOSTA_STATUS = 'resposta.status';
    const RESPOSTA_PAGINACIO = 'resposta.paginacio';

    useEffect(() => {
        if (isAuthenticated && accessToken) {
            obtenirItemsLaMevaLlista();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, accessToken]);

    const isStorageAvailable = () => {
        return !!(typeof window !== 'undefined' && window?.CCMAStorage);
    };
    const shouldProcess = () => {
        return isAuthenticated && isStorageAvailable();
    };
    const shouldFetch = () => {
        //toca mirar ccmastorage o api?
        const itemsNotLoaded = __isEmpty(itemsLaMevaLlista);
        const itemsExpired = Date.now() - lastFetchedTimestamp > expiresInMinutes * 60000;
        return itemsExpired || itemsNotLoaded;
    };

    const getItemsFromCCMAStorage = async () => {
        return await getStorageValue(['_ccma_lamevallista_3catinfo_', uuid].join(''));
    };

    const obtenirItemsLaMevaLlista = async () => {
        if (shouldProcess() && shouldFetch()) {
            let items = {};
            const plainData = await getItemsFromCCMAStorage();
            try {
                items = JSON.parse(plainData);
            } catch (e) {
                items = {};
            }
            if (__isEmpty(items)) {
                items = await getItemsFromApi();
            }

            setItemsLaMevaLlista(items);
            setLoaded(true);
            setLastFetchedTimestamp(Date.now());
        }
    };
    const isStatusOk = (rawData) => {
        return __get(rawData, RESPOSTA_STATUS) === 'OK';
    };

    const removeItemToAPI = async (id, tipologia) => {
        if (!isAuthenticated || !accessToken) return false;
        const url = `${urlBase}/usuaris/llistes/esborrarcontingut?tipus_llista=permestard&tipologia=${tipologia}&contingut_id=${id}&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista-remove', { url, fetchFromOrigin: true }] });
        return isStatusOk(rawData);
    };
    const addItemToAPI = async (id, tipologia) => {
        if (!isAuthenticated || !accessToken) return false;
        const url = `${urlBase}/usuaris/llistes/afegircontingut?tipus_llista=permestard&tipologia=${tipologia}&contingut_id=${id}&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista-add', { url, fetchFromOrigin: true }] });
        return isStatusOk(rawData);
    };

    const getItemsFromApi = async () => {
        if (!isAuthenticated || !accessToken) return false;
        const url = `${urlBase}/usuaris/llistes/obtenircontinguts?_format=json&tipus_llista=permestard` +
        `&tipologia=NOT_NOTICIA,NOT_AUTOR,DTY_CATEGORY&cache=30&vista=HISTORIC&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista', { url, fetchFromOrigin: true }] });
        let items = [];

        if (isStatusOk(rawData)) {
            items = rawData.resposta?.items?.item?.reduce((list, item) => {
                if (!list[item.tipologia]) {
                    list[item.tipologia] = [];
                }
                list[item.tipologia].push(Number(item.contingut_id));

                return list;
            }, {});
            setItemsToCCMAStorage(items);
        }
        return items;
    };

    const getFullItemsFromApi = async (tipologia, pagina, numItems) => {
        if (
            !isAuthenticated ||
            !accessToken ||
            itemsLaMevaLlista['NOT_NOTICIA'] === undefined ||
            itemsLaMevaLlista['NOT_NOTICIA'].length === 0
        )
            return false;
        setIsLoading(true);

        const urlNot = `${urlBase}/usuaris/llistes/obtenircontinguts?_format=json&tipus_llista=permestard&tipologia=NOT_NOTICIA` + 
        `&items_pagina=${numItems}&pagina=${pagina}&cache=30&master=yes&usuari_id=${accessToken}`;
        const notRawData = await getData({ queryKey: ['itemsLaMevaLlista', { url: urlNot, fetchFromOrigin: true }] });

        const isStatusNotisOk = __get(notRawData, RESPOSTA_STATUS) === 'OK';
        const items = isStatusNotisOk ? __get(notRawData, 'resposta') : [];

        let blocsInfoContingut = Array.isArray(items?.items?.item)
            ? items.items.item.map((item) => item.info_contingut) // Extrae solo info_contingut
            : [];

        const paginacio = isStatusNotisOk ? __get(notRawData, RESPOSTA_PAGINACIO) : [];
        const moreData = { paginacio, url: urlNot };

        setIsLoading(false);
        return { blocsInfoContingut, moreData };
    };

    const getFullCategoriesInfo = async () => {
        if (!isAuthenticated || !accessToken || itemsLaMevaLlista['DTY_CATEGORY']?.length === 0) return false;

        setIsLoading(true);

        let idsParamsCategories = '&id=' + Object.values(itemsLaMevaLlista['DTY_CATEGORY']).join(',');

        const urlCategories = `${urlBase}/categories?_format=json&${idsParamsCategories}&debug=no&version=2.0&cache=90`;
        const categoriesRawData = await getData({ queryKey: ['itemsCategories', { url: urlCategories, fetchFromOrigin: true }] });

        const isStatusNotisOk = __get(categoriesRawData, RESPOSTA_STATUS) === 'OK';
        const items = isStatusNotisOk ? __get(categoriesRawData, 'resposta') : [];
        const blocsInfoCategories = Array.isArray(items?.items?.item) ? items?.items.item : [];

        const idsOrdenats = [...itemsLaMevaLlista['DTY_CATEGORY']].reverse(); // Invertim l'array d'IDs

        const blocsInfoCategoriesOrdenats = idsOrdenats
            .map((id) => blocsInfoCategories.find((categoria) => categoria.idint == id)) // Buscar cada autor en l'ordre invertit
            .filter((categoria) => categoria); // Elimina valors undefined si n'hi han

        setIsLoading(false);
        return { blocsInfoCategories: blocsInfoCategoriesOrdenats };
    };

    const getFullItemsFromApiDtyCategory = async (tipologia, pagina, numItems, selectedTematicaId, categories, noIds) => {
        if (!isAuthenticated || !accessToken || __isEmpty(itemsLaMevaLlista)) return false;
        setIsLoading(true);
        const noIdsParam = noIds ? '&no_id=' + Object.values(noIds).join(',') : '';

        let idsParamsCategories =
            itemsLaMevaLlista?.[tipologia]
                ? '&tag=' + categories.map((item) => item.id).join(',')
                : '';

        if (selectedTematicaId && selectedTematicaId !== 'TOTS') {
            idsParamsCategories = '&tag=' + selectedTematicaId;
        }

        const urlNot = `${urlBase}/noticies?_format=json${idsParamsCategories}&no_agrupacio=NOMOSTRAUTOR&origen=llistat&items_pagina=${numItems}`+
        `&pagina=${pagina}&sdom=img&debug=no&version=2.0&cache=90&redl=false&master=yes&perfils_extra=imatges_3catinfo${noIdsParam}`;
        const notRawData = await getData({ queryKey: ['itemsNoticies', { url: urlNot, fetchFromOrigin: true }] });

        const isStatusNotisOk = __get(notRawData, RESPOSTA_STATUS) === 'OK';
        const items = isStatusNotisOk ? __get(notRawData, 'resposta') : [];
        const blocsInfoContingut = Array.isArray(items?.items?.item) ? items?.items.item : [];

        const paginacio = isStatusNotisOk ? __get(notRawData, RESPOSTA_PAGINACIO) : [];
        const moreData = { paginacio, url: urlNot };

        setIsLoading(false);
        return { blocsInfoContingut, moreData };
    };

    const getFullAutorsInfo = async () => {
        if (!isAuthenticated || !accessToken || itemsLaMevaLlista['NOT_AUTOR'].length === 0) return false;

        setIsLoading(true);

        let idsParamsAutors = '&id=' + Object.values(itemsLaMevaLlista['NOT_AUTOR']).join(',');

        const urlAutors = `${urlBase}/autor?_format=json${idsParamsAutors}&sdom=img&origen=llistat&debug=no&version=2.0&pagina=1&cache=90&redl=false&master=no`;
        const autorsRawData = await getData({ queryKey: ['itemsAutors', { url: urlAutors, fetchFromOrigin: true }] });

        const isStatusNotisOk = __get(autorsRawData, RESPOSTA_STATUS) === 'OK';
        const items = isStatusNotisOk ? __get(autorsRawData, 'resposta') : [];
        const blocsInfoAutors = Array.isArray(items?.items?.item) ? items?.items.item : [];

        const idsOrdenats = [...itemsLaMevaLlista['NOT_AUTOR']].reverse(); // Invertim l'array d'IDs

        const blocsInfoAutorsOrdenats = idsOrdenats
            .map((id) => blocsInfoAutors.find((autor) => autor.id == id)) // Buscar cada autor en l'ordre invertit
            .filter((autor) => autor); // Elimina valors undefined si n'hi han

        setIsLoading(false);
        return { blocsInfoAutors: blocsInfoAutorsOrdenats };
    };

    const getFullItemsFromApiNotAutor = async (tipologia, pagina, numItems, selectedFirmaId, noIds) => {
        if (!isAuthenticated || !accessToken || __isEmpty(itemsLaMevaLlista)) return false;
        setIsLoading(true);
        const noIdsParam = noIds ? '&no_id=' + Object.values(noIds).join(',') : '';

        let idsParamsAutors =
            itemsLaMevaLlista?.[tipologia]
                ? '&autor_id=' + Object.values(itemsLaMevaLlista[tipologia]).join(',')
                : '';

        if (selectedFirmaId && selectedFirmaId !== 'TOTES') {
            idsParamsAutors = '&autor_id=' + selectedFirmaId;
        }

        const urlNot = `${urlBase}/noticies?_format=json${idsParamsAutors}&no_agrupacio=NOMOSTRAUTOR&origen=llistat`+
        `&items_pagina=${numItems}&pagina=${pagina}&sdom=img&debug=no&version=2.0&cache=90&redl=false&master=yes&perfils_extra=imatges_3catinfo${noIdsParam}`;
        const notRawData = await getData({ queryKey: ['itemsNoticies', { url: urlNot, fetchFromOrigin: true }] });

        const isStatusNotisOk = __get(notRawData, RESPOSTA_STATUS) === 'OK';
        const items = isStatusNotisOk ? __get(notRawData, 'resposta') : [];
        const blocsInfoContingut = Array.isArray(items?.items?.item) ? items?.items.item : [];

        const paginacio = isStatusNotisOk ? __get(notRawData, RESPOSTA_PAGINACIO) : [];
        const moreData = { paginacio, url: urlNot };

        setIsLoading(false);
        return { blocsInfoContingut, moreData };
    };

    const setItemsToCCMAStorage = (items) => {
        const plainData = JSON.stringify(items);
        setStorageValue(['_ccma_lamevallista_3catinfo_', uuid].join(''), plainData, {
            path: '/',
            domain: '.3cat.cat',
            expires: expires
        });
    };

    const isALaMevaLlista = (idint, tipologia) => {
        return itemsLaMevaLlista?.[tipologia]?.includes(Number(idint));
    };

    const eliminaALaMevaLlista = async (idint, tipologia) => {
        let isStatusOk = false;
        setIsLoading(true);
        const items = itemsLaMevaLlista;
        if (items[tipologia]) {
            const itemId = typeof idint === 'number' ? Number(idint) : idint;
            const index = items[tipologia].indexOf(itemId);
            if (index > -1) {
                items[tipologia].splice(index, 1);
                updateItemsLaMevaLlista(items);
                isStatusOk = removeItemToAPI(idint, tipologia);
            }
        }
        setIsLoading(false);
        return isStatusOk;
    };

    const afegeixALaMevaLlista = async (idint, tipologia) => {
        let isStatusOk = false;
        setIsLoading(true);
        let items = itemsLaMevaLlista;
        if (!items[tipologia]) {
            items[tipologia] = [];
        }
        if (!isALaMevaLlista(idint, tipologia)) {
            const itemId = typeof idint === 'number' ? Number(idint) : idint;
            items[tipologia].push(itemId);
            updateItemsLaMevaLlista(items);
            isStatusOk = addItemToAPI(idint, tipologia);
        }
        setIsLoading(false);
        return isStatusOk;
    };
    const updateItemsLaMevaLlista = (items) => {
        setItemsToCCMAStorage(items);
        setItemsLaMevaLlista(items);
    };

    const toggleSelection = async (id, tipologia) => {
        let result = false;

        // Clonar l'estat actual
        const newItems = { ...itemsLaMevaLlista };

        if (!newItems[tipologia]) {
            newItems[tipologia] = [];
        }

        const index = newItems[tipologia].indexOf(id);
        if (index === -1) {
            // Afegir el nou ID i esperar la resposta de l'API
            newItems[tipologia] = [...newItems[tipologia], id];
            result = await afegeixALaMevaLlista(id, tipologia);
        } else {
            // Eliminar el ID i esperar la resposta de l'API
            newItems[tipologia] = newItems[tipologia].filter((itemId) => itemId !== id);
            result = await eliminaALaMevaLlista(id, tipologia);
        }

        // Actualitzar l'estat només després de completar la crida a l'API
        setItemsLaMevaLlista(newItems);

        return result;
    };

    const contextValue = useMemo(() => ({
        itemsLaMevaLlista,
        toggleSelection,
        isALaMevaLlista,
        eliminaALaMevaLlista,
        afegeixALaMevaLlista,
        getFullItemsFromApi,
        getFullItemsFromApiDtyCategory,
        getFullCategoriesInfo,
        getFullItemsFromApiNotAutor,
        getFullAutorsInfo,
        isLoading,
        loaded
    }), [
        itemsLaMevaLlista,
        toggleSelection,
        isALaMevaLlista,
        eliminaALaMevaLlista,
        afegeixALaMevaLlista,
        getFullItemsFromApi,
        getFullItemsFromApiDtyCategory,
        getFullCategoriesInfo,
        getFullItemsFromApiNotAutor,
        getFullAutorsInfo,
        isLoading,
        loaded
    ]);

    return (
        <ContentUserContext.Provider
            value={contextValue}
        >
            {children}
        </ContentUserContext.Provider>
    );
};

export default ContentUserProvider;
