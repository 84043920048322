import dynamic from 'next/dynamic';

const trescatinfoComponents = {
    AtencioAudiencia: { dynamicComponent: dynamic(() => import(`components/3catinfo/AtencioAudiencia`)) },
    AreaUsuaris: { dynamicComponent: dynamic(() => import(`components/3catinfo/AreaUsuaris`)) },
    Audiencies: { dynamicComponent: dynamic(() => import(`components/3catinfo/Audiencies`)) },
    Autor: { dynamicComponent: dynamic(() => import(`components/3catinfo/Autor`)) },
    AvuiEsNoticia: { dynamicComponent: dynamic(() => import(`components/3catinfo/AvuiEsNoticia`)) },
    BlocAreaUsuaris: { dynamicComponent: dynamic(() => import(`components/3catinfo/BlocAreaUsuaris`)) },
    BlocREDL: { dynamicComponent: dynamic(() => import(`components/3catinfo/BlocREDL`)) },
    BlocComodiSeccio: { dynamicComponent: dynamic(() => import(`components/3catinfo/BlocComodiSeccio`)) },
    Boto: { dynamicComponent: dynamic(() => import(`components/3catinfo/Boto`)) },
    BotoSeguir: { dynamicComponent: dynamic(() => import(`components/3catinfo/BotoSeguir`)) },
    BotonsTendenciesClimatiques: { dynamicComponent: dynamic(() => import(`components/3catinfo/TendenciesClimatiques/Botons`)) },
    BotonsPrevisio: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/BotonsPrevisio`)) },
    BustiaDenuncia: { dynamicComponent: dynamic(() => import(`components/3catinfo/BustiaDenuncia`)) },
    CallToAction: { dynamicComponent: dynamic(() => import(`components/3catinfo/CallToAction`)) },
    CameraEmbed: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/CameraEmbed`)) },
    Capcalera: { dynamicComponent: dynamic(() => import(`components/3catinfo/Capcalera`)) },
    CapcaleraAutor: { dynamicComponent: dynamic(() => import(`components/3catinfo/CapcaleraAutor`)) },
    CapcaleraTemps: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/CapcaleraTemps`)) },
    CapMinutaminut: { dynamicComponent: dynamic(() => import(`components/3catinfo/CapMinutaminut`)) },
    CercaMunicipi: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/CercaMunicipi`)) },
    Columna: { dynamicComponent: dynamic(() => import(`components/3catinfo/Columna`)) },
    Compartir: { dynamicComponent: dynamic(() => import(`components/3catinfo/Compartir`)) },
    Contenidor: { dynamicComponent: dynamic(() => import(`components/3catinfo/Contenidor`)) },
    ContingutEstatic: { dynamicComponent: dynamic(() => import(`components/3catinfo/ContingutEstatic`)) },
    ContinuaLlegint: { dynamicComponent: dynamic(() => import(`components/3catinfo/ContinuaLlegint`)) },
    DescarregaApp: { dynamicComponent: dynamic(() => import(`components/3catinfo/DescarregaApp`)) },
    Destacat: { dynamicComponent: dynamic(() => import(`components/3catinfo/Destacat`)) },
    DestacatMapaAnimat: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/DestacatMapaAnimat`)) },
    DestacatTendenciesClimatiques: {
        dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/DestacatTendenciesClimatiques`))
    },
    Embed: { dynamicComponent: dynamic(() => import(`components/3catinfo/Embed`)) },
    EnCincMinuts: { dynamicComponent: dynamic(() => import(`components/3catinfo/EnCincMinuts`)) },
    Etiqueta: { dynamicComponent: dynamic(() => import(`components/3catinfo/Etiqueta`)) },
    Estructura: { dynamicComponent: dynamic(() => import(`components/3catinfo/Estructura`)) },
    Fila: { dynamicComponent: dynamic(() => import(`components/3catinfo/Fila`)) },
    Firmes: { dynamicComponent: dynamic(() => import(`components/3catinfo/Firmes`)) },
    Grafica: { dynamicComponent: dynamic(() => import(`components/3catinfo/Grafica`)) },
    GraficaEleccions: { dynamicComponent: dynamic(() => import(`components/3catinfo/Eleccions/Grafica`)) },
    ItemAutor: { dynamicComponent: dynamic(() => import(`components/3catinfo/ItemAutor`)) },
    ItemMinutaminut: { dynamicComponent: dynamic(() => import(`components/3catinfo/ItemMinutaminut`)) },
    ItemNoticia: { dynamicComponent: dynamic(() => import(`components/3catinfo/ItemNoticia`)) },
    Joc: { dynamicComponent: dynamic(() => import(`components/3catinfo/Joc`)) },
    LesMevesFirmes: { dynamicComponent: dynamic(() => import(`components/3catinfo/LesMevesFirmes`)) },
    Llistat: { dynamicComponent: dynamic(() => import(`components/3catinfo/Llistat`)) },
    LlistatFirmes: { dynamicComponent: dynamic(() => import(`components/3catinfo/LlistatFirmes`)) },
    LlistatMunicipisTendenciesClimatiques: {
        dynamicComponent: dynamic(() => import(`components/3catinfo/TendenciesClimatiques/LlistatMunicipis`))
    },
    LlistatNoticies: { dynamicComponent: dynamic(() => import(`components/3catinfo/LlistatNoticies`)) },
    LlistatCameres: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/LlistatCameres`)) },
    LlistatPrevisioMunicipis: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/LlistatPrevisioMunicipis`)) },
    LlistatTemes: { dynamicComponent: dynamic(() => import(`components/3catinfo/LlistatTemes`)) },
    LlistatTemesLletres: { dynamicComponent: dynamic(() => import(`components/3catinfo/LlistatTemes/LlistatTemesLletres`)) },
    Mapbox: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/Mapbox`)) },
    MarcatgeEstructurat: { dynamicComponent: dynamic(() => import(`components/3catinfo/MarcatgeEstructurat`)) },
    Menu: { dynamicComponent: dynamic(() => import(`components/3catinfo/Menu`)) },
    MenuSeccio: { dynamicComponent: dynamic(() => import(`components/3catinfo/MenuSeccio`)) },
    Metadades: { dynamicComponent: dynamic(() => import(`components/3catinfo/Metadades`)) },
    Meteodades: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/Meteodades`)) },
    MeuEntorn: { dynamicComponent: dynamic(() => import(`components/3catinfo/MeuEntorn`)) },
    Modal: { dynamicComponent: dynamic(() => import(`components/3catinfo/Modal`)) },
    ModuldAvis: { dynamicComponent: dynamic(() => import(`components/3catinfo/ModuldAvis`)) },
    MomentsClau: { dynamicComponent: dynamic(() => import(`components/3catinfo/ItemMinutaminut/MomentsClau`)) },
    Mosaic: { dynamicComponent: dynamic(() => import(`components/3catinfo/Mosaic`)) },
    MostraMes: { dynamicComponent: dynamic(() => import(`components/3catinfo/MostraMes`)) },
    Newsletter: { dynamicComponent: dynamic(() => import(`components/3catinfo/Newsletter`)) },
    NotFound: { dynamicComponent: dynamic(() => import(`components/3catinfo/NotFound`)) },
    Peu: { dynamicComponent: dynamic(() => import(`components/3catinfo/Peu`)) },
    Player: { dynamicComponent: dynamic(() => import(`components/3catinfo/Player`)) },
    Players: { dynamicComponent: dynamic(() => import(`components/3catinfo/Players`)) },
    Podcastaldia: { dynamicComponent: dynamic(() => import(`components/3catinfo/Podcastaldia`)) },
    PrevisioRecents: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/PrevisioRecents`)) },
    PrevisioMunicipi: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/PrevisioMunicipi`)) },
    Publicitat: { dynamicComponent: dynamic(() => import(`components/3catinfo/Publicitat`)) },
    Reels: { dynamicComponent: dynamic(() => import(`components/3catinfo/Reels`)) },
    SliderCameres: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/SliderCameres`)) },
    Social: { dynamicComponent: dynamic(() => import(`components/3catinfo/Social`)) },
    Tab: { dynamicComponent: dynamic(() => import(`components/3catinfo/Tabs/Tab`)) },
    TabsCustom: { dynamicComponent: dynamic(() => import(`components/3catinfo/Tabs/TabsCustom`)) },
    TabList: { dynamicComponent: dynamic(() => import(`components/3catinfo/Tabs/TabList`)) },
    TabPanel: { dynamicComponent: dynamic(() => import(`components/3catinfo/Tabs/TabPanel`)) },
    TagsSeo: { dynamicComponent: dynamic(() => import(`components/3catinfo/TagsSeo`)) },
    TaulaExtrems: { dynamicComponent: dynamic(() => import(`components/3catinfo/ElTemps/TaulaExtrems`)) },
    Text: { dynamicComponent: dynamic(() => import(`components/3catinfo/Text`)) },
    TiraFirmes: { dynamicComponent: dynamic(() => import(`components/3catinfo/TiraFirmes`)) },
    TiraMobile: { dynamicComponent: dynamic(() => import(`components/3catinfo/TiraMobile`)) },
    TiraRanking: { dynamicComponent: dynamic(() => import(`components/3catinfo/TiraRanking`)) },
    TiraVegueries: { dynamicComponent: dynamic(() => import(`components/3catinfo/TiraVegueries`)) },
    Titol: { dynamicComponent: dynamic(() => import(`components/3catinfo/Titol`)) },
    UltimesNoticies: { dynamicComponent: dynamic(() => import(`components/3catinfo/UltimesNoticies`)) },
    ZonaEditable: { dynamicComponent: dynamic(() => import(`components/3catinfo/ZonaEditable`)) }
};

export { trescatinfoComponents };
