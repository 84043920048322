import Boto from '../Boto/Boto';
import styles from './notfound.module.scss';

export default function NotFound(props) {
    const { code, text, cleanUrlParams } = props;

    return (
        <div className={styles.pre_notfound}>
            <div className={styles.notfound} data-testid={`error-${code}`}>
                <div>
                    <p className={styles.codi}>{code}</p>
                    <h3 className={styles.titol}>{text}</h3>
                </div>
                <Boto
                    tag='a'
                    text='Ves a la portada'
                    className={styles.boto}
                    route={{ href: '/3catinfo' }}
                    cleanUrlParams={cleanUrlParams}
                />
            </div>
        </div>
    );
}

NotFound.defaultProps = {
    text: '',
    cleanUrlParams: false
};
