import React, { createContext, useState, useEffect, useRef } from 'react';

export const PublicitatContext = createContext();



const PublicitatProvider = (props) => {
    const { children } = props;
    const [setupPubliFinished, setSetupPubliFinished] = useState(false);
    const hasTopBannerPublicitat = useRef({
        publiFounded: false, // Valor inicial
        SCROLL_THRESHOLD: 3  // Valor inicial basado en publiFounded
    });

    const _setupSmart = async () => {
        let sasSetup = {
            networkid: 1029, 
            domain: "https://www3.smartadserver.com", 
            async: true, 
            renderMode: 2
        }
    
        /* Mirem si tenim la cookie informada per les apps per agafar el uid, appName i el built*/
        if(window.CCMAStorage){
            const cookieSmartPlain = await window.CCMAStorage.get('CCMA_Smart_Apps');
            try {
                if(cookieSmartPlain) {
                    const cookieSmart = JSON.parse(cookieSmartPlain);
                    sasSetup.uid = cookieSmart.uid;
                    sasSetup.appName = cookieSmart.appName;
                    sasSetup.bundleId = cookieSmart.bundleId;
                }
            } catch(e) {
                console.log("publicitat - cookieSmartPlain no te el format esperat");
            }
        }
        window.sas?.cmd?.push(function() {
            window.sas.setup(sasSetup);
            setSetupPubliFinished(true);
        });
    }

    const findPublicitatNode = (children) => {
        if (!children || !children.props || !Array.isArray(children.props.children)) {
            return false;
        }
    
        //Buscar "Publicitat" directament al children
        const hasPublicitatDirectly = children.props.children.some(child =>
            child?.props?.layout?.structure?.some(item => item.name === "Publicitat")
        );
    
        if (hasPublicitatDirectly) return true;
    
        //Si no està directament, buscar dins del "Contenidor"
        return children.props.children.some(child =>
            child?.props?.layout?.structure?.some(contenidor =>
                contenidor.name === "Contenidor" &&
                Array.isArray(contenidor.children) &&
                contenidor.children.some(subChild => subChild.name === "Publicitat")
            )
        );
    };
    

    useEffect(() => {
        const publiFounded = findPublicitatNode(children);
        hasTopBannerPublicitat.current = {publiFounded, SCROLL_THRESHOLD: publiFounded ? 264 : 3};
    }, [children]);

    useEffect(() => {
        _setupSmart();
    }, []);

    return <PublicitatContext.Provider value={{ setupPubliFinished, hasTopBannerPublicitat }} children={children} />;
};


export default PublicitatProvider;
